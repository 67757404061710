import { KeycloakConfig } from 'keycloak-angular';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';

const keycloakConfig: KeycloakConfig = {
  url: 'https://account-dev.urbanobank.com/auth/',
  realm: 'urbanobank-sdx',
  clientId: 'wl-webbanking',
};

const wlThemeParameterization: LayoutConfigModel = {
  wlTheme: {
    wlName: 'Urbano Bank',
    realm: 'urbanobank',
    theme: 'Sentinel',
    favicon: './assets/whitelabel/urbanobank/icon/favicon.png',
    optionalPages: true,
    languageDefault: 'pt-br',
    limitNotification: true,
    company: {
      name: 'URBANO BANK INTERMEDIACAO E MEIOS DE PAGAMENTOS S.A.',
      city: 'Barueri',
      state: 'São Paulo',
      address: 'Alameda Rio Negro, 585, 10º ANDAR - CONJ: 101/102 - EDIF: JACARI',
      zipCode: '06454-000',
      cnpj: '37.828.752/0001-55',
      site: 'https://www.bancourbano.com.br/',
      emailSac: 'ouvidoria@bancourbano.com.br',
    },
    application: [
      /*{
        label: 'Link de Pagamento',
        img: './assets/images/notifications/grid/payment.svg',
        url: 'https://appsandbox.payshop.com.br/App/dashboard',
        blank: true,
        roles: ['gtw-client'],
        isVisible: true,
      },
      {
        label: 'Backoffice',
        img: './assets/images/notifications/grid/backoffice.svg',
        url: 'https://bo.sandbox.urbanobank.com',
        blank: true,
        roles: ['bo-access'],
        isVisible: true,
      },*/
    ],
    intro: {
      brand: {
        logo: './assets/whitelabel/urbanobank/brand/brand-secondary-large.svg',
      },
      background: {
        image: './assets/whitelabel/urbanobank/background/background-horizontal-large.svg',
        color: 'transparent',
      },
      text: {
        color: '#ffffff',
      },
      button: {
        background: {
          color: {
            primary: 'transparent',
            secondary: 'linear-gradient(to right, #B2833A 45%, #e0d671)',
          },
          hover: {
            primary: '#031029',
            secondary: '#FF9600',
          },
        },
        text: {
          color: {
            primary: '#ffffff',
            secondary: '#ffffff',
          },
        },
        border: {
          color: {
            primary: '#ffffff',
            secondary: 'transparent',
          },
        },
      },
      social: {
        show: false,
        color: '#ffffff',
      },
      footer: {
        brand: './assets/whitelabel/urbanobank/brand/brand-secondary-small.svg',
        background: {
          color: 'transparent',
        },
        border: {
          color: '#B2833A',
        },
      },
    },
    timeline: {
      brand: {
        logo: './assets/whitelabel/urbanobank/brand/brand-secondary-small.svg',
      },
      background: {
        image: './assets/whitelabel/urbanobank/background/background-vertical-large.svg',
        color: 'transparent',
      },
      text: {
        color: {
          primary: '#ffffff',
          secondary: '#ffffff',
        },
      },
      bullet: {
        color: {
          primary: '#ffffff',
          secondary: 'transparent',
          tertiary: '#FF9600',
        },
        border: {
          color: '#ffffff',
        },
      },
    },
    aside: {
      brand: {
        logo: './assets/whitelabel/urbanobank/brand/brand-secondary-small.svg',
      },
      background: {
        color: '#031029',
      },
      text: {
        color: '#ffffff',
        hover: '#ffffff',
      },
    },
    header: {
      billet: {
        brand: {
          logo: './assets/whitelabel/urbanobank/brand/brand-primary-small.svg',
        },
      },
      brand: {
        logo: {
          primary: './assets/whitelabel/urbanobank/brand/brand-secondary-small.svg',
          secondary: './assets/whitelabel/urbanobank/brand/brand-monochrome-small.svg',
        },
      },
      background: {
        image: './assets/whitelabel/urbanobank/background/background-horizontal-small.svg',
        color: {
          primary: '#031029',
          secondary: '#FF9600',
        },
      },
      button: {
        background: {
          color: {
            primary: '#FF9600',
            secondary: '#ffffff',
          },
        },
        text: {
          color: {
            primary: '#ffffff',
            secondary: '#031029',
          },
        },
      },
      text: {
        color: {
          primary: '#ffffff',
          secondary: '#031029',
          tertiary: '#ffffff',
        },
      },
      profile: {
        background: {
          image: './assets/whitelabel/urbanobank/background/background-profile.svg',
          color: '',
        },
      },
    },
    onboarding: {
      text: {
        color: {
          primary: '#FF9600',
          secondary: '#031029',
          tertiary: '#ffffff',
        },
      },
      button: {
        background: {
          color: {
            primary: '#FF9600',
            secondary: '#ffffff',
          },
        },
        text: {
          color: {
            primary: '#031029',
            secondary: '#FF9600',
            tertiary: '#ffffff',
          },
        },
      },
      message: {
        color: {
          info: '#003f8a',
          success: '#031029',
          warn: '#FF9600',
          error: '#FF9600',
        },
      },
    },
    color: {
      primary: '#031029',
      secondary: '#FF9600',
    },
    message: {
      color: {
        info: '#031029',
        success: '#031029',
        warn: '#FF9600',
        error: '#FF9600',
      },
    },
    button: {
      background: {
        color: {
          info: '#031029',
          success: '#031029',
          warn: '#FF9600',
          error: '#FF9600',
          contrast: '#FFFFFF',
        },
      },
      text: {
        color: {
          info: '#FFFFFF',
          success: '#FFFFFF',
          warn: '#031029',
          error: '#FFFFFF',
          contrast: '#031029',
        },
      },
    },
    font: {
      size: '100%',
      family: '',
    },
    global: {
      button: {
        text: {
          color: '#ffffff',
        },
      },
      extractColors: {
        creditColor: '#031029',
        debitColor: '#FF9600',
      },
    },
    storeImage: {
      apple: {
        visible: false,
        link: '',
      },
      google: {
        visible: false,
        link: '',
      },
    },
    images: [
      {
        tag: 'intro',
        path: 'assets/images/whitelabel/urbanobank/intro/slide-small-1.png',
        name: 'Slide Intro',
        url: null,
        external: false,
        description: 'Slide Intro',
      },
      {
        tag: 'intro',
        path: 'assets/images/whitelabel/urbanobank/intro/slide-small-2.png',
        name: 'Slide Intro',
        url: null,
        external: false,
        description: 'Slide Intro',
      },
      {
        tag: 'intro',
        path: 'assets/images/whitelabel/urbanobank/intro/slide-small-3.png',
        name: 'Slide Intro',
        url: null,
        external: false,
        description: 'Slide Intro',
      },
      {
        tag: 'swiper-square',
        path: './assets/whitelabel/urbanobank/banner/banner-square-1.png',
        name: 'Swiper',
        url: null,
        external: false,
        description: 'Swipper',
      },
      {
        tag: 'swiper-full',
        path: './assets/whitelabel/urbanobank/banner/banner-full-5.png',
        name: 'Swiper',
        url: 'https://www.newvalue.com.br/activate_cpf/?uuid=f8855863-3514-46a0-8b8e-25d581464f2e',
        external: true,
        description: 'Swipper',
      },
      {
        tag: 'swiper-full',
        path: './assets/whitelabel/urbanobank/banner/banner-full-1.png',
        name: 'Swiper',
        url: null,
        external: false,
        description: 'Swipper',
      },
      {
        tag: 'swiper-full',
        path: './assets/whitelabel/urbanobank/banner/banner-full-2.png',
        name: 'Swiper',
        url: null,
        external: false,
        description: 'Swipper',
      },
      {
        tag: 'swiper-full',
        path: './assets/whitelabel/urbanobank/banner/banner-full-3.png',
        name: 'Swiper',
        url: null,
        external: false,
        description: 'Swipper',
      },
      {
        tag: 'transfer',
        path: './assets/images/cash-in/account-share/transfer.svg',
        name: 'Transfer',
        url: null,
        external: false,
        description: 'Transfer',
      },
      {
        tag: 'change-password',
        path: './assets/images/my-data/change-password.svg',
        name: 'change-password',
        url: null,
        external: false,
        description: 'change-password',
      },
      {
        tag: 'accept-token',
        path: './assets/images/my-data/accept-token.svg',
        name: 'accept-token',
        url: null,
        external: false,
        description: 'accept-token',
      },
      {
        tag: 'error-token',
        path: './assets/images/my-data/error-token.svg',
        name: 'error-token',
        url: null,
        external: false,
        description: 'error-token',
      },
      {
        tag: 'confirmated',
        path: 'assets/images/cash-out/payment-notification/confirmado.svg',
        name: 'confirmado',
        url: null,
        external: false,
        description: 'Confirmated',
      },
      {
        tag: 'recused',
        path: 'assets/images/cash-out/payment-notification/recusado.svg',
        name: 'recusado',
        url: null,
        external: false,
        description: 'Recused',
      },
      {
        tag: 'barcode',
        path: 'assets/images/dashboard/barcode-outline.svg',
        name: 'barcode-outline',
        url: null,
        external: false,
        description: 'Recused',
      },
      {
        tag: 'card',
        path: 'assets/images/dashboard/card.svg',
        name: 'card',
        url: null,
        external: false,
        description: 'Card',
      },
      {
        tag: 'earnings',
        path: 'assets/images/dashboard/earnings.svg',
        name: 'earnings',
        url: null,
        external: false,
        description: 'Earnings',
      },
      {
        tag: 'loan',
        path: 'assets/images/dashboard/loan.svg',
        name: 'loan',
        url: null,
        external: false,
        description: 'Loan',
      },
      {
        tag: 'sales',
        path: 'assets/images/dashboard/sales.svg',
        name: 'sales',
        url: null,
        external: false,
        description: 'Sales',
      },
      {
        tag: 'swap',
        path: 'assets/images/dashboard/swap.svg',
        name: 'swap',
        url: null,
        external: false,
        description: 'Swap',
      },
      {
        tag: 'device',
        path: 'assets/images/device-validation/device.svg',
        name: 'device',
        url: null,
        external: false,
        description: 'Device',
      },
      {
        tag: 'send-code',
        path: 'assets/images/device-validation/send-code.svg',
        name: 'send-code',
        url: null,
        external: false,
        description: 'Send Code',
      },
      {
        tag: 'avatar',
        path: 'assets/images/header-web/avatar.svg',
        name: 'avatar',
        url: null,
        external: false,
        description: 'Avatar',
      },
      {
        tag: 'payment',
        path: 'assets/images/notifications/grid/payment.svg',
        name: 'payment',
        url: null,
        external: false,
        description: 'Payment',
      },
      {
        tag: 'supermarket',
        path: 'assets/images/notifications/grid/supermarket.svg',
        name: 'supermaket',
        url: null,
        external: false,
        description: 'Supermarket',
      },
      {
        tag: 'analise-concluded',
        path: 'assets/images/notifications/status-account/concluido-analise.svg',
        name: 'concluido-analise',
        url: null,
        external: false,
        description: 'Analise Concluded',
      },
      {
        tag: 'devolved-document',
        path: 'assets/images/notifications/status-account/documento-devolvido.svg',
        name: 'documento-devolvido',
        url: null,
        external: false,
        description: 'Devolved Document',
      },
      {
        tag: 'success',
        path: 'assets/images/notifications/status-response/success.svg',
        name: 'success',
        url: null,
        external: false,
        description: 'Success',
      },
      {
        tag: 'success',
        path: 'assets/images/notifications/status-response/success.svg',
        name: 'success',
        url: null,
        external: false,
        description: 'Success',
      },
      {
        tag: 'billet',
        path: 'assets/images/onboarding-ec/billet-icon.svg',
        name: 'billet-icon',
        url: null,
        external: false,
        description: 'Billet',
      },
      {
        tag: 'card-machine',
        path: 'assets/images/onboarding-ec/card-machine-icon.svg',
        name: 'card-machine-icon',
        url: null,
        external: false,
        description: 'Card Machine',
      },
      {
        tag: 'credit-card',
        path: 'assets/images/onboarding-ec/credit-card-icon.svg',
        name: 'credit-card-icon',
        url: null,
        external: false,
        description: 'Credit Card',
      },
      {
        tag: 'energy',
        path: 'assets/images/onboarding-ec/energy-icon.svg',
        name: 'energy-icon',
        url: null,
        external: false,
        description: 'Energy',
      },
      {
        tag: 'camera',
        path: 'assets/images/onboarding-ec/icone foto.svg',
        name: 'icone foto',
        url: null,
        external: false,
        description: 'Camera',
      },
      {
        tag: 'gallery',
        path: 'assets/images/onboarding-ec/icone galeria.svg',
        name: 'icone galeria',
        url: null,
        external: false,
        description: 'Gallery',
      },
      {
        tag: 'credit-card',
        path: 'assets/images/onboarding-ec/credit-card-icon.svg',
        name: 'credit-card-icon',
        url: null,
        external: false,
        description: 'Credit Card',
      },
      {
        tag: 'showcase',
        path: 'assets/images/onboarding-ec/tela 1 vitrine.svg',
        name: 'tela 1 vitrine',
        url: null,
        external: false,
        description: 'Tela 1 Vitrine',
      },
      {
        tag: 'showcase',
        path: 'assets/images/onboarding-ec/tela 2 vitrine.svg',
        name: 'tela 2 vitrine',
        url: null,
        external: false,
        description: 'Tela 2 Vitrine',
      },
      {
        tag: 'showcase',
        path: 'assets/images/onboarding-ec/tela 3 vitrine.svg',
        name: 'tela 3 vitrine',
        url: null,
        external: false,
        description: 'Tela 3 Vitrine',
      },
      {
        tag: 'legal-person',
        class: 'wlp-icon-onboarding-pj',
        path: 'assets/images/sign-up/account-type/legalPerson.svg',
        name: 'legalPerson',
        url: null,
        external: false,
        description: 'Legal Person',
      },
      {
        tag: 'mei',
        class: 'wlp-icon-onboarding-mei',
        path: 'assets/images/sign-up/account-type/mei.svg',
        name: 'mei',
        url: null,
        external: false,
        description: 'MEI',
      },
      {
        tag: 'mei-white',
        path: 'assets/images/sign-up/account-type/mei white.svg',
        name: 'mei white',
        url: null,
        external: false,
        description: 'MEI White',
      },
      {
        tag: 'physical-person',
        class: 'wlp-icon-onboarding-pf',
        path: 'assets/images/sign-up/account-type/physicalPerson.svg',
        name: 'physicalPerson',
        url: null,
        external: false,
        description: 'Physical Person',
      },
      {
        tag: 'self-employed',
        class: 'icon-selfEmployed',
        path: 'assets/images/sign-up/account-type/selfEmployed.svg',
        name: 'selfEmployed',
        url: null,
        external: false,
        description: 'Self Employed',
      },
      {
        tag: 'accept-mei',
        class: 'icon-acceptMei',
        path: 'assets/images/sign-up/choose-document/acceptMei.svg',
        name: 'acceptMei',
        url: null,
        external: false,
        description: 'Accept MEI',
      },
      {
        tag: 'meeting',
        class: 'wlp-icon-onboarding-ata-assembleia',
        path: 'assets/images/sign-up/choose-document/ata.svg',
        name: 'ata',
        url: null,
        external: false,
        description: 'Minutes of the Meeting',
      },
      {
        tag: 'choose-document',
        path: 'assets/images/sign-up/choose-document/choose-document.svg',
        name: 'choose-document',
        url: null,
        external: false,
        description: 'Choose Document',
      },
      {
        tag: 'cnh',
        class: 'wlp-icon-onboarding-cnh',
        path: 'assets/images/sign-up/choose-document/cnh.svg',
        name: 'cnh',
        url: null,
        external: false,
        description: 'CNH',
      },
      {
        tag: 'cnpj',
        class: 'wlp-icon-onboarding-cartao-cnpj',
        path: 'assets/images/sign-up/choose-document/cnpj.svg',
        name: 'cnpj',
        url: null,
        external: false,
        description: 'CNPJ',
      },
      {
        tag: 'contract',
        class: 'wlp-icon-onboarding-contrato-social',
        path: 'assets/images/sign-up/choose-document/contract.svg',
        name: 'contract',
        url: null,
        external: false,
        description: 'Contract',
      },
      {
        tag: 'university-degree',
        class: 'icon-diploma',
        path: 'assets/images/sign-up/choose-document/diploma.svg',
        name: 'diploma',
        url: null,
        external: false,
        description: 'University Degree',
      },
      {
        tag: 'procuration',
        class: 'wlp-icon-onboarding-procuracao',
        path: 'assets/images/sign-up/choose-document/procuration.svg',
        name: 'procuration',
        url: null,
        external: false,
        description: 'Procuration',
      },
      {
        tag: 'attorney-letter',
        class: 'wlp-icon-onboarding-mei',
        path: 'assets/images/sign-up/account-type/mei.svg',
        name: 'attorney-letter',
        url: null,
        external: false,
        description: 'attorney-letter',
      },
      {
        tag: 'proof-address',
        class: 'wlp-icon-onboarding-comprovante-residencia',
        path: 'assets/images/sign-up/choose-document/acceptMei.svg',
        name: 'acceptMei',
        url: null,
        external: false,
        description: 'Proof Address',
      },
      {
        tag: 'rg',
        class: 'wlp-icon-onboarding-rg',
        path: 'assets/images/sign-up/choose-document/RG-icon2.svg',
        name: 'RG-icon2',
        url: null,
        external: false,
        description: 'RG',
      },
      {
        tag: 'selfie',
        class: 'wlp-icon-onboarding-selfie',
        path: 'assets/images/sign-up/choose-document/selfie.svg',
        name: 'selfie',
        url: null,
        external: false,
        description: 'Selfie',
      },
      {
        tag: 'adrress-pin',
        class: 'icon-pin',
        path: 'assets/images/sign-up/document-type/pin.svg',
        name: 'pin',
        url: null,
        external: false,
        description: 'Address Pin',
      },
      {
        tag: 'upload-concluded',
        path: 'assets/images/sign-up/upload-document/concluido-upload.svg',
        name: 'concluido-upload',
        url: null,
        external: false,
        description: 'Upload Concluded',
      },
      {
        tag: 'jpg',
        path: 'assets/images/sign-up/upload-document/jpg-image.svg',
        name: 'jpg-image',
        url: null,
        external: false,
        description: 'JPG',
      },
      {
        tag: 'pdf',
        path: 'assets/images/sign-up/upload-document/pdf-image.svg',
        name: 'pdf-image',
        url: null,
        external: false,
        description: 'PDF',
      },
      {
        tag: 'upload-document',
        path: 'assets/images/sign-up/upload-document/upload-document.svg',
        name: 'upload-document',
        url: null,
        external: false,
        description: 'Upload Document',
      },
      {
        tag: 'warning',
        path: 'assets/images/sign-up/warning-alert/warning.svg',
        name: 'warning-alert',
        url: null,
        external: false,
        description: 'Warning Alert',
      },
      {
        tag: 'warning-circle',
        path: 'assets/images/sign-up/warning-alert/warning-circle.svg',
        name: 'warning-circle-alert',
        url: null,
        external: false,
        description: 'Warning Circle Alert',
      },
      {
        tag: 'mastercard-flag',
        path: 'assets/images/card-manager/icn-mastercard.svg',
        name: 'mastercard-flag',
        url: null,
        external: false,
        description: 'Mastercard Flag',
      },
      {
        tag: 'email-send',
        path: 'assets/images/card-manager/email-send.svg',
        name: 'email-send',
        url: null,
        external: false,
        description: 'email-send',
      },
      {
        tag: 'card-request',
        path: 'assets/images/card-manager/card-request.svg',
        name: 'card-request',
        url: null,
        external: false,
        description: 'Card Request',
      },
      {
        tag: 'solicitation-card',
        path: 'assets/images/cards/cardSolicitation.svg',
        name: 'solicitation-card',
        url: null,
        external: false,
        description: 'Solicitation Card',
      },
      {
        tag: 'solicitation-sucess-card',
        path: 'assets/images/cards/cardSolicitationSucess.svg',
        name: 'solicitation-sucess-card',
        url: null,
        external: false,
        description: 'Solicitation Sucess Card',
      },
    ],
  },
};

export const environment = {
  production: false,
  wlp: '17a403c1-ebb9-2f36-aaa9-e09e0545778f', // White label com todos os fluxos
  apiUri: 'https://apiquery-dev.urbanobank.com',
  keyForm: 'keyForm',
  typeUpload: 'typeUpload',
  authTokenKey: '',
  keyAccount: null,
  keycloakConfig,
  wlThemeParameterization,
  appVersion: require('../../package.json').version,
  keyStorage: {
    menu: '8b23bf11-372e-411b-acae-051450c83406',
    userEscrow: 'b9ac9133-d250-4451-9868-ebff8166084a',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
